<template>
  <div class="container-xxl gx-5">
    <SingularTaskLayout
      ref="taskLayout"
      :title="$t('document.documentRecording.title')"
      :task="currentTask || {}"
      :is-loading-task="tasksLoading"
      :validate-task-before-complete-hook="submitTask"
      :is-actions-disabled="!currentDoc"
      :should-disable-ignore-warnings="shouldDisableIgnoreWarnings"
      @complete-task="() => {}"
      @skip-task="onSkipTask"
    >
      <template slot="menu-dropdown-items">
        <el-dropdown-item>
          <div @click="toggleDeclarationRequired">
            <ReturnIcon width="24px" height="24px" color="black" />
            <span class="mx-1">
              {{
                documentationRequired
                  ? $t('document.documentRecording.removeDocumentationRequired')
                  : $t('document.documentRecording.documentationRequired')
              }}
            </span>
          </div>
        </el-dropdown-item>
        <el-dropdown-item>
          <div class="text-danger" @click="removeDocument">
            <TrashCanIcon width="24px" height="24px" />
            <span class="mx-1">
              {{ $t('document.documentsOverview.classificationTasks.menuOptions.removeDocument') }}
            </span>
          </div>
        </el-dropdown-item>
      </template>
      <template #content>
        <div class="d-flex justify-content-between mb-4">
          <div>
            <span v-if="documentationRequired" class="review-required-badge text-primary badge p-2">{{
              $t('document.documentRecording.documentationRequired')
            }}</span>
          </div>
        </div>

        <div v-if="documentErrors && documentErrors.length" class="card error-card p-4 mb-5">
          <div class="d-flex align-items-center gap-2">
            <NoticeIcon class="text-danger" width="18" height="18" />
            <p class="fw-bold">{{ $t('document.documentRecording.validation.errorBox.title') }}</p>
          </div>
          <ol>
            <li v-for="(error, i) in documentErrors" :key="i">
              <p v-if="error.fields.length">
                {{
                  $t('document.documentRecording.validation.errorBox.unbalanced', {
                    fields: error.fields
                      .slice(0, -1)
                      .map((field) => $t(`document.exports.schema.fields.${getFieldTranslationKey(field)}`))
                      .join(', '),
                    lastField: $t(
                      `document.exports.schema.fields.${getFieldTranslationKey(error.fields.slice(-1)[0])}`
                    ),
                  })
                }}
              </p>
            </li>
          </ol>
        </div>

        <div class="row form-size-limit">
          <div class="col-md-7 h-100">
            <template v-if="currentDoc">
              <document-form
                :key="currentDoc.id"
                ref="docForm"
                :doc="recordedDoc"
                :disable-actions="managerReviewRequired"
                @change="documentChanged"
              />
            </template>
          </div>

          <div class="col-md-5 h-100 overflow-auto">
            <document-ocr-manager
              v-if="currentDoc"
              :doc="currentDoc"
              :doc-template-data="docTemplateData"
              @textExtraction="onTextExtraction"
              @dataExtraction="updateForm"
              @documentRecognition="updateForm"
            />
          </div>
        </div>

        <ValidationWarningsModal
          v-if="validationWarnings.length"
          class="modalIndex"
          :warnings="validationWarnings"
          @close="handleCloseWarningsModal"
          @warnings-debunked="handleClearWarnings"
        />
      </template>
      <template #play-mode-indicator>
        <slot name="play-mode-indicator"></slot>
      </template>
    </SingularTaskLayout>
  </div>
</template>

<script type="text/javascript">
import { isNil, reject, omit, pick, clone } from 'ramda';
import { ref, computed } from 'vue';

import { ParamsSchema, ItemsParamsSchema, ReferencesParamsSchema } from '@/imports/api/schemas/documents';

import { NoticeIcon, TrashCanIcon, ReturnIcon } from '@/assets/icons';
import { DOCUMENT_QUERY_NEW2, convertDocument } from '@/modules/document/compositions/documents-queries';
import { recordDocument, deleteDocument, updateDocument } from '@/modules/document/compositions/documents-operations';
import { DocumentForm, DocumentOcrManager, ValidationWarningsModal } from '@/modules/document/components';
import { getDefaultParams, BaseDocumentSchema } from '@/imports/api/schemas/documents';
import { SingularTaskLayout } from '@/modules/tasks/task/components';
import { DOCUMENT_FLAGS } from '@/modules/document/types';
import DocumentSchemas from '@/imports/api/schemas/documents';
// Analytics
import { ANALYTICS_ENUMS } from '@/modules/document/utils/analytics';
import { ANALYTICS_EVENT_TYPES } from '@/analytics/types';
import { DOC_ANALYTICS_EVENT_TYPES } from '@/analytics/types/doc-analytics-types';
import { useAnalytics } from '@/analytics/compositions/useAnalytics';

const getUnconfiguredStructureParams = (structureParams, paramsSchema) =>
  paramsSchema.filter((key) => !structureParams[key]);

export default {
  meteor: {},
  components: {
    DocumentForm,
    DocumentOcrManager,
    NoticeIcon,
    ValidationWarningsModal,
    SingularTaskLayout,
    ReturnIcon,
    TrashCanIcon,
  },
  props: {
    task: { type: Object, required: true, default: () => ({}) },
    isLoadingTask: { type: Boolean, required: true, default: () => true },
  },
  setup(props, { emit }) {
    const DEFAULT_WAIT_TIME_FOR_WORKFLOW = 2500;
    const waitForWorkflowTaskProcess = () =>
      // The task isn't closed here and handled later by document workflow therefore we must wait until document-workflow finish
      new Promise((resolve) => setTimeout(resolve, DEFAULT_WAIT_TIME_FOR_WORKFLOW));

    const { logEvent } = useAnalytics();

    const currentTask = computed(() => props.task);
    const tasksLoading = computed(() => props.isLoadingTask);

    const shouldDisableIgnoreWarnings = ref(true);

    const onSkipTask = () => emit('on-task-skip');

    return {
      logEvent,
      tasksLoading,
      validationWarnings: ref([]),
      validationErrors: ref([]),
      currentTask,
      openSnoozeModal: ref(false),
      shouldDisableIgnoreWarnings,
      onSkipTask,
      waitForWorkflowTaskProcess,
    };
  },
  data() {
    return {
      currentDoc: null,
      recordedDoc: null,
      copyMode: false,
    };
  },
  computed: {
    schema() {
      const type = this.currentDoc?.type;
      return type ? DocumentSchemas[type] : BaseDocumentSchema;
    },

    managerReviewRequired() {
      return this.currentTask?.reviewRequired;
    },
    documentationRequired() {
      return this.currentDoc?.hasDeclaration ?? false;
    },
    docTemplateData() {
      const { supplierId, type: documentType } = this.currentDoc || {};
      return { supplierId, documentType };
    },
    documentErrors() {
      return this.validationErrors.find(({ path }) => !path.length)?.context?.invalidRelations;
    },
  },
  watch: {
    'currentDoc.id': {
      handler(value) {
        if (value)
          this.recordedDoc = {
            ...this.currentDoc,
            structureParams: this.currentDoc.structureParams || getDefaultParams(this.currentDoc.type),
          };
        this.validationWarnings = [];
        this.validationErrors = [];
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    getFieldTranslationKey(field) {
      switch (field) {
        case 'items.totalAmount':
          return 'itemsTotalAmount';
        case 'generalCharges.amount':
          return 'generalChargesAmount';
        default:
          return field;
      }
    },

    async toggleDeclarationRequired() {
      const currentFormData = this.schema.clean(this.$refs.docForm.model);
      const currentDocumentFlags = pick(Object.values(DOCUMENT_FLAGS), this.currentDoc);
      const toggledValue = !currentDocumentFlags[DOCUMENT_FLAGS.HAS_DECLARATION];
      currentDocumentFlags[DOCUMENT_FLAGS.HAS_DECLARATION] = toggledValue;
      this.recordedDoc.hasDeclaration = toggledValue;
      await this.saveDocument({ ...currentFormData, ...currentDocumentFlags });
      this.currentDoc[DOCUMENT_FLAGS.HAS_DECLARATION] = toggledValue;
    },

    onKeydown(e) {
      if (e.key === 'c' && e.metaKey) this.copyMode = true;
    },

    onTextExtraction(texts) {
      if (this.copyMode) {
        navigator.clipboard.writeText(texts.join('\n'));
        this.$message('Copied to clipboard');
        this.copyMode = false;
      } else {
        this.$refs.docForm.insertDataToLastFocusedField(texts);
      }
    },

    updateForm(data) {
      this.shouldDisableIgnoreWarnings = true;
      this.$refs.docForm.updateForm(data);
    },

    async removeDocument() {
      const confirmationResult = await this.$confirm(
        this.$i18n.t('document.documentsOverview.recordingTasks.menuOptions.removeDialog.message'),
        this.$i18n.t('document.documentsOverview.recordingTasks.menuOptions.removeDialog.title'),
        {
          confirmButtonText: this.$i18n.t('document.documentsOverview.recordingTasks.menuOptions.removeDialog.confirm'),
          cancelButtonText: this.$i18n.t('document.documentsOverview.recordingTasks.menuOptions.removeDialog.cancel'),
          type: 'warning',
        }
      ).catch(() => {
        return 'canceled';
      });

      if (confirmationResult !== 'confirm') return;
      const loading = this.$loading();
      try {
        await deleteDocument({ id: this.currentDoc.id });
        await this.waitForWorkflowTaskProcess();
        this.$emit('on-task-complete');
        this.$message.success(this.$i18n.tc('document.documentsOverview.recordingTasks.message.removed'));
      } catch (err) {
        this.$message.error(`${err.error} - ${err.reason}`);
      } finally {
        loading.close();
      }
    },

    notifyTaskLayoutOnFormDataChange(formData) {
      if (this.$refs.taskLayout && this.$refs.taskLayout.onContentChanged) {
        this.$refs.taskLayout.onContentChanged({ businessId: this.currentDoc.businessId, ...formData });
      }
    },

    async documentChanged(formData) {
      await this.saveDocument(formData);

      this.notifyTaskLayoutOnFormDataChange(formData);
    },

    async saveDocument(formData) {
      this.shouldDisableIgnoreWarnings = true;

      this.currentDoc.replicateOf = formData.replicateOf;
      if (formData.paymentMethod) {
        if (isNil(formData.paymentMethod.type)) formData.paymentMethod = undefined;
        else
          formData.paymentMethod = {
            type: formData.paymentMethod.type,
            [formData.paymentMethod.type]: formData.paymentMethod[formData.paymentMethod.type],
          };
      }
      try {
        await updateDocument({
          id: this.currentDoc.id,
          data: this.convertISODates(formData),
        });
      } catch (err) {
        if (err.graphQLErrors?.length) {
          const { body } = err.graphQLErrors[0].extensions.response;
          this.$notify.error({ title: 'Save failed', message: body.message });
          console.error('save failed', body);
        } else {
          this.$notify.error({ title: 'Save failed', message: err.toString() });
          console.error('save failed', err);
        }
      }
    },

    convertISODates(document) {
      const clonedDocument = clone(reject(isNil)(document));
      const isoDatesFields = [
        'issueDate',
        'deliveryDate',
        'paymentDate',
        'paymentDueDate',
        'referencesFromDate',
        'referencesToDate',
      ];
      for (const isoDateField of isoDatesFields) {
        if (!isNil(clonedDocument[isoDateField]))
          clonedDocument[isoDateField] = clonedDocument[isoDateField].toISOString().split('T')[0];
      }

      if (!isNil(clonedDocument.references) && Array.isArray(clonedDocument.references))
        clonedDocument.references = clonedDocument.references.map((reference) => this.convertISODates(reference));

      return clonedDocument;
    },

    async submitTask() {
      const loading = this.$loading();
      this.shouldDisableIgnoreWarnings = false;

      const taskId = this.currentTask.id;
      await this.$nextTick();

      const result = await this.$refs.docForm.validateAndGetCleanedModel();

      if (isNil(result)) {
        loading.close();
        throw new Error('form validation invalid');
      }

      const { formData, setErrors: setErrorsCallback } = result;

      try {
        if (isNil(formData)) {
          loading.close();
          throw new Error('form validation invalid');
        }

        const currentDocId = this.currentDoc.id;
        const clearedDocumentData = this.clearFieldsNotConfiguredInStructureParams(formData);

        if (currentDocId !== this.currentDoc.id) {
          return { errors: [], warnings: [], shouldAbortCompletion: true };
        }

        const currentDocumentFlags = pick(Object.values(DOCUMENT_FLAGS), this.currentDoc);
        await updateDocument({
          id: this.currentDoc.id,
          data: this.convertISODates({
            ...clearedDocumentData,
            ...currentDocumentFlags,
          }),
        });

        await recordDocument({ id: this.currentDoc.id, ignoreWarnings: false });
        this.logEvent(ANALYTICS_EVENT_TYPES.TASKS.COMPLETED, {
          task_id: taskId,
          source_page: ANALYTICS_ENUMS.SOURCE_PAGE.RECORD,
          source_url: this.route?.fullPath,
          page_section: ANALYTICS_ENUMS.PAGE_SECTION.HEADER,
          outcome_type: ANALYTICS_ENUMS.OUTCOME_TYPE.SUCCESS,
          outcome_details: null,
        });

        await this.waitForWorkflowTaskProcess();
        this.$message.success('Document recorded');
        this.$emit('on-task-complete');

        return { errors: [], warnings: [], shouldAbortCompletion: true }; // shouldAbortCompletion is true because when document get recorded the task will be completed by document-workflow and we dont want it to be completed by taskLayout
      } catch (error) {
        const errors = [];
        if (error.graphQLErrors) {
          const { body } = error.graphQLErrors[0].extensions.response;
          if (body.details) {
            const validationErrors = body.details.filter((error) => !error.isWarning);
            const warningsResults = body.details.filter((error) => error.isWarning);
            if (!validationErrors.length && warningsResults.length) {
              this.validationWarnings = warningsResults;
              this.logEvent(ANALYTICS_EVENT_TYPES.TASKS.VALIDATION_WARNINGS, {
                task_id: taskId,
                source_page: ANALYTICS_ENUMS.SOURCE_PAGE.RECORD,
                source_url: this.route?.fullPath,
                page_section: ANALYTICS_ENUMS.PAGE_SECTION.HEADER,
                outcome_type: ANALYTICS_ENUMS.OUTCOME_TYPE.WARNING,
                outcome_details: JSON.stringify(warningsResults),
              });
            }

            if (validationErrors.length) {
              this.validationErrors = validationErrors;
              setErrorsCallback(validationErrors);
              this.$message.error(`${body.message} - ${validationErrors[0].path.join('.')}`);
              errors.push(this.$t('document.documentRecording.generalRecordingError'));
              this.logEvent(ANALYTICS_EVENT_TYPES.TASKS.VALIDATION_ERRORS, {
                task_id: taskId,
                source_page: ANALYTICS_ENUMS.SOURCE_PAGE.RECORD,
                source_url: this.route?.fullPath,
                page_section: ANALYTICS_ENUMS.PAGE_SECTION.HEADER,
                outcome_type: ANALYTICS_ENUMS.OUTCOME_TYPE.ERROR,
                outcome_details: JSON.stringify(validationErrors),
              });
            }
          } else {
            console.error('recording failed A:', body);
            throw new Error(body);
          }
          console.error('recording failed B:', body);
        } else {
          console.error('recording failed C:', error);
          throw new Error(error.toString());
        }

        return { errors, warnings: [], shouldAbortCompletion: true };
      } finally {
        loading.close();
      }
    },

    async handleClearWarnings(warningsAnswers) {
      const loading = this.$loading();
      this.validationWarnings = [];
      try {
        const analyticTaskId = this.currentTask.id;
        const analyticCurrentUrl = this.route?.fullPath;

        await recordDocument({ id: this.currentDoc.id, ignoreWarnings: true });
        this.logEvent(DOC_ANALYTICS_EVENT_TYPES.MODAL_INTERACTION, {
          task_id: analyticTaskId,
          source_page: ANALYTICS_ENUMS.SOURCE_PAGE.RECORD,
          source_url: analyticCurrentUrl,
          modal_type: ANALYTICS_ENUMS.MODAL_TYPE.WARNING,
          modal_description: JSON.stringify(warningsAnswers ?? {}),
          trigger_action: ANALYTICS_ENUMS.TRIGGER_ACTION.COMPLETE_BUTTON_CLICKED,
          user_action_result: ANALYTICS_ENUMS.USER_ACTION_RESULT.SAVE,
          modal_interaction_value: JSON.stringify(warningsAnswers ?? {}),
        });

        await this.waitForWorkflowTaskProcess();
        this.$message.success('Document recorded');
        this.$emit('on-task-complete');
      } catch (err) {
        this.$message.error(err.toString());
        console.error('recording failed', err);
      } finally {
        loading.close();
      }
    },

    clearFieldsNotConfiguredInStructureParams(documentData) {
      const { structureParams } = documentData;

      const unconfiguredDocumentParams = getUnconfiguredStructureParams(structureParams, ParamsSchema.objectKeys());
      const unconfiguredItemsParams = structureParams.items
        ? getUnconfiguredStructureParams(structureParams.items, ItemsParamsSchema.objectKeys())
        : [];
      const unconfiguredReferencesParams = structureParams.references
        ? getUnconfiguredStructureParams(structureParams.references, ReferencesParamsSchema.objectKeys())
        : [];
      const unconfiguredReferenceItemsParams = structureParams.references?.items
        ? getUnconfiguredStructureParams(structureParams.references.items, ItemsParamsSchema.objectKeys())
        : [];

      return omit(unconfiguredDocumentParams, {
        ...documentData,
        items: documentData.items?.map(omit(unconfiguredItemsParams)),
        references: documentData.references?.map((reference) =>
          omit(unconfiguredReferencesParams, {
            ...reference,
            items: reference.items?.map(omit(unconfiguredReferenceItemsParams)),
          })
        ),
      });
    },

    handleCloseWarningsModal({ trigger, warningsAnswers }) {
      this.validationWarnings = [];
      this.logEvent(DOC_ANALYTICS_EVENT_TYPES.MODAL_INTERACTION, {
        task_id: this.currentTask.id,
        source_page: ANALYTICS_ENUMS.SOURCE_PAGE.RECORD,
        source_url: this.route?.fullPath,
        modal_type: ANALYTICS_ENUMS.MODAL_TYPE.WARNING,
        modal_description: JSON.stringify(warningsAnswers ?? {}),
        trigger_action: ANALYTICS_ENUMS.TRIGGER_ACTION.COMPLETE_BUTTON_CLICKED,
        user_action_result:
          trigger === 'PRESSED_X'
            ? ANALYTICS_ENUMS.USER_ACTION_RESULT.CLOSE
            : ANALYTICS_ENUMS.USER_ACTION_RESULT.CANCEL,
        modal_interaction_value: JSON.stringify(warningsAnswers ?? {}),
      });
    },
  },
  apollo: {
    currentDoc: {
      query: DOCUMENT_QUERY_NEW2,
      update: (data) => convertDocument(data.documentNew2),
      variables() {
        return { id: this.currentTask.data.documentId };
      },
      skip() {
        return !this.currentTask;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.review-required-badge {
  background: #f5f7fd;
}

.review-required-comment-alert {
  display: flex;
  flex-direction: row;
  background-color: #f9f2e8;
  padding: 16px;
  border-radius: 4px;
  height: 52px;
  color: #b85c00;
  font-size: small;
}

.review-required-comment-alert > div {
  margin-right: 10px;
}

.form-size-limit {
  height: calc(100vh - 148px);
  overflow: hidden;
}

.error-card {
  border-color: $error;
  background: change-color($color: $error, $lightness: 98%);
  color: $typography-primary;
}

.drop-down-btn {
  background-color: #f2f3f5;
  width: 30px;
  height: 30px;
}

.container-xxl {
  width: 100%;
  padding: 0 1.5rem;
  max-width: unset;
  margin: 0 auto;
}
</style>
