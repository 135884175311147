import { render, staticRenderFns } from "./DocumentClassificationTaskSingularLayout.vue?vue&type=template&id=cdb6c652&scoped=true"
import script from "./DocumentClassificationTaskSingularLayout.vue?vue&type=script&lang=js"
export * from "./DocumentClassificationTaskSingularLayout.vue?vue&type=script&lang=js"
import style0 from "./DocumentClassificationTaskSingularLayout.vue?vue&type=style&index=0&id=cdb6c652&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdb6c652",
  null
  
)

export default component.exports